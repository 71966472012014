import React from 'react'
import Form from "../components/form"

const Banner = ({title, mobileTitle}) =>{
    return(
        <>  
            <p className="h3 m0 mt20 m-p20 m-m0 m-show">{mobileTitle}</p>
            <p className="h3 m0 max-350 mb40 mt20 m-p20 t-pl20 m-m0 m-hide">{title}</p>
            <Form />
        </>
    )
}
export default Banner