import * as React from "react"
import Layout from "../components/layout"
import Introduction from "../components/introduction"
import TextBanner from "../components/textBanner"
import Seo from "../components/seo"
import ContactInformation from "../components/contactInformation"
import FormWrapper from "../components/formWrapper"
import Map from "../components/map"
import { graphql } from "gatsby"

const ContactPage = ({data}) => {

  const seoInfo = data.datoCmsContactPage
  return(
  <Layout page="Contact">
    <Seo title={seoInfo.metaTitle} description={seoInfo.metaDescription} />

    <Introduction title="Contact Us" />

    <div className="flex m-wrap m-direction-reverse max-1000 ma bt1 m-b0 pt20 mp0 mb40">
      <div className="w-70 m-100 pr20 mp0 m-mt20 m-bt1">
        <FormWrapper title="Fill in the form below and we will be in touch as soon as possible" mobileTitle="Alternatively, fill in the form below and we will be in touch as soon as possible" />
      </div>
      <div className="w-30 m-100 bl1">
        <p className="m0 m-show p20 bb1 h3">You can reach us here</p>
        <ContactInformation email="admin@lewisandtrovas.com" phone="(07) 3333 2858" location="Level 7, 10 Market St, Brisbane CBD QLD 4000" postal="GPO Box 3222 Brisbane CBD QLD 4001" />
      </div>
    </div>
    <div className="spacer half m-hide"></div>

    <Map />

    <TextBanner button="Get Started" buttonWhite="true" link="https://lewisandtrovas.settify.com.au/landing?referral=homepage" background="#A69EA9" title="Alternatively, get started with our team today" text={data.datoCmsContactPage.alternativelyGetStartedWithOurTeamToday} />

  </Layout>)
}

export default ContactPage

export const query = graphql`
query{
  datoCmsContactPage {
    alternativelyGetStartedWithOurTeamToday
    metaDescription
    metaTitle
  }
}
`
