import * as React from "react"

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      message: "",
      dropdown:""

    }
    
  }
  componentDidMount() {
    document.querySelector("form").addEventListener("submit", this.handleSubmit)
    if(this?.props?.linkState?.productName){
      this.setState({message:`I'd like to enquire about `+this.props.linkState.productName})
      this.setState({dropdown:true}) 
    }
   
  }
  

  handleSubmit = e => {

    e.preventDefault()
    let myForm = document.getElementById("contact")
    let formData = new FormData(myForm)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        const form = document.querySelector("form")
        form.classList.add("fade-out")
        setTimeout(() => {
          this.setState({ formSubmitted: true },()=>{
            const thankYou = document.querySelector("#thank-you")
            setTimeout(()=>{
              thankYou.classList.add("fade-in-thank-you")
            },100)
           
          })
        }, 1250)
      })
    .catch(error => console.error(error))
    document.querySelector("form").addEventListener("submit", this.handleSubmit);
  }

  render() {
    const formSubmitted = this.state.formSubmitted
    
    if(!formSubmitted){
    return (
        <form method="POST" data-netlify="true" id="contact" className="form-section flex flex-wrap mr20 m-m0 m-p20 t-pl20">
            
            <input type="hidden" name="form-name" value="contact" />

            <div className="w-50 m-100 flex flex-wrap">
                <label className="uppercase mb10" htmlFor="First Name">First Name *</label>
              <input name="First Name" type="text" required className="w-100 input mr20 m-m0"/>
            </div>

            <div className="w-50 m-100 flex flex-wrap m-mt20">
                <label className="uppercase mb10" htmlFor="Last Name">Last Name *</label>
                <input name="Last Name" type="text" required className="w-100 input"/>
            </div>

            <div className="w-50 m-100 flex flex-wrap mt20">
                <label className="uppercase mb10" htmlFor="Email">Email *</label>
                <input name="Email" required type="text" className="w-100 input mr20 m-m0"/>
            </div>

            <div className="w-50 m-100 flex flex-wrap mt20">
                <label className="uppercase mb10" htmlFor="Phone">Phone</label>
                <input name="Phone" type="text" className="w-100 input"/>
            </div>

            <div className="flex flex-wrap mt20 w-100">
                <label className="uppercase mb10" htmlFor="howContacted">How would you like to be contacted?</label>
                <div className="dropdown-wrapper w-100">
                    <select name="howContacted" className="dropdown input w-100" defaultValue="default">
                        <option disabled defaultValue value="default">Please select an option</option>
                        <option value="Referral">Phone</option>
                        <option value="Word Of Mouth">Email</option>
                        <option value="Online">Phone & Email</option>
                    </select>
                </div>
            </div> 
            
            <div className="flex flex-wrap mt20 w-100">
                <label className="uppercase mb10" htmlFor="hearUs">How did you hear about us?</label>
                <div className="dropdown-wrapper w-100">
                    <select name="hearUs" className="dropdown input w-100" defaultValue="default">
                        <option disabled defaultValue value="default">Please select an option</option>
                        <option value="Recommended by a friend">Recommended by a friend or colleague</option>
                        <option value="Recommended by a previous client">Recommended by a previous or current client of the firm</option>
                        <option value="Search Engine">Search engine (eg. Google search)</option>
                        <option value="Social Media">Social media (eg. Facebook or LinkedIn)</option>
                        <option value="Advertisement">Advertisement</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>

            <div className="w-100 flex flex-wrap mb40 mt20">
                <label className="uppercase mb10" htmlFor="message">Your Message</label>
                <textarea placeholder='Please type your message here...' className="w-100  text-area input" name="message" type="text" id="message" />
            </div>
            <button type="submit" className="button black mla">Submit</button>

        </form>
    )}else{
      return <div className="form-section t-pl20 thank-you" id="thank-you"> Thank You for submitting</div>
    }
  }
}

export default EnquiryForm
