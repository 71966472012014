import React from 'react'

const ContactInformation = ({email, phone, location, postal}) =>{
    return(
        <div className="contact--information pl20 pr20">
            <p className="m0 mb40 m-hide">Alternatively, you<br/> can reach us here.</p>
            <div className="contact-info mb40 m-pt20">
                <p className="uppercase m0 bb1 m-b0 pb10 mb20 m-hide">Contact</p>
                <a className="link sainte" href={"mailto:"+email}>{email}</a> 
                <a className="link sainte w-100 inline" href={"tel:"+phone}>{phone}</a>
            </div> 
            <div className="location mb40">
                <p className="uppercase m0 bb1 pb10 mb20">location</p>
                <a className="link sainte inline" target="_blank" rel="noreferrer" style={{'maxWidth':'190px'}}  href={"https://www.google.com/maps/place/"+location}>{location}</a> 
            </div> 
            <div className="postal-address">
                <p className="uppercase m0 bb1 pb10 mb20">postal address</p>
                <p className=" sainte inline m0" style={{'maxWidth':'170px'}}>{postal}</p> 
            </div> 
        </div>
    )
}
export default ContactInformation